import { singleDateStringFirstMonth } from "@/app/helpers/timeHelpers/timeHelpers";
import styles from "@/app/styles/Atoms/Date.module.scss";
import style from "@/app/styles/Molecules/StoryPageHeadline.module.scss";
import Icon from "../../Icon/Icon";

type props = {
	isText: boolean;
	textModify: string;
	datePublished?: string | any;
	dateModified?: string | any;
	typeMedia?: string;
};

const DateModified = ({
	isText,
	textModify,
	dateModified,
	typeMedia,
}: props) => {
	if (!dateModified) return <></>;
	return (
		<>
			<time
				dateTime={dateModified}
				className={`${styles?.date} ${styles.updated_date} ${
					typeMedia && typeMedia === "multiVideo"
						? styles["date__liveblog__multiVideo"]
						: typeMedia && typeMedia === "multiImage"
						? styles["date__liveblog__multiImage"]
						: ""
				}`}
			>
				<Icon
					name="update"
					className={`${style["spHeadline__lastUpdate__icon"]}`}
				/>{" "}
				Actualizado{" "}
				{isText ? textModify : singleDateStringFirstMonth(dateModified ?? "")}{" "}
				CST
			</time>
		</>
	);
};

export default DateModified;
