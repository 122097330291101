import dynamic from "next/dynamic";
import styles from "@/app/styles/SocialShareButtons/SocialShareButtons.module.scss";
const ButtonShareSocial = dynamic(
	() => import("@/app/components/molecules/ButtonShareSocial/ButtonShareSocial")
);
/**
 *
 * Social Share Buttons
 * This component Allow share the videos on the social Media
 * @param {String} props.title Title of the video
 * @param {String} props.description Description of the video
 * @param {String} props.link Link of the Video
 */

type Props = {
	title?: string;
	description?: string;
	link?: string;
	isVariantStoryPage?: boolean;
	variantSpecial?: boolean;
	isLoading?: boolean;
	isVariantVideoPlayerList?: boolean;
	isVariantDetailTalent?: boolean;
	liveBlog?: boolean;
};

const SocialShareButtons = ({
	title = "",
	description = "",
	link = "/",
	isVariantStoryPage = false,
	variantSpecial = false,
	isVariantVideoPlayerList = false,
	isVariantDetailTalent = false,
	liveBlog = false,
}: Props) => {
	const iconSize: number = isVariantStoryPage
		? 22
		: isVariantVideoPlayerList || isVariantDetailTalent
		? 24
		: 18;
	return (
		<div
			className={`	
				${styles.share} 
				${isVariantStoryPage ? styles.share__story : ""} 
				${isVariantVideoPlayerList ? styles.share__videoPlayerList : ""}
				${isVariantDetailTalent ? styles.share__detailTalent : ""}
				${variantSpecial ? styles.share__special : ""}
				${liveBlog ? styles.share__liveBlog : ""}
			`}
		>
			<p
				className={`${styles.share__title} ${
					isVariantStoryPage ? styles.share__title__story : ""
				} ${
					isVariantVideoPlayerList ? styles.share__title__videoPlayerList : ""
				}
				`}
			>
				COMPARTE:
			</p>

			<ButtonShareSocial
				isVariantStoryPage={isVariantStoryPage}
				variant={"Copy"}
				customName={"Copiar link"}
				shareTitle={title}
				shareDescription={description}
				shareUrl={link}
				isLiveBlogLayer={liveBlog}
				isBordered={false}
			/>
			<ButtonShareSocial
				isVariantStoryPage={isVariantStoryPage}
				variant={"Whatsapp"}
				shareTitle={title}
				shareDescription={description}
				shareUrl={link}
				isLiveBlogLayer={liveBlog}
			/>
			<ButtonShareSocial
				isVariantStoryPage={isVariantStoryPage}
				variant={"Facebook"}
				shareTitle={title}
				shareDescription={description}
				shareUrl={link}
				isLiveBlogLayer={liveBlog}
			/>
			<ButtonShareSocial
				isVariantStoryPage={isVariantStoryPage}
				variant={"Twitter"}
				shareTitle={title}
				shareDescription={description}
				shareUrl={link}
				isLiveBlogLayer={liveBlog}
			/>
			<ButtonShareSocial
				isVariantStoryPage={isVariantStoryPage}
				variant={"Threads"}
				shareTitle={title}
				shareDescription={description}
				shareUrl={link}
				isLiveBlogLayer={liveBlog}
			/>
		</div>
	);
};

export default SocialShareButtons;
