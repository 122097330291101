import dynamic from "next/dynamic";
import fetchApolloData from "@/app/helpers/fetchApolloData/fetchApolloData";
import GetProgramsHome from "@/app/queries/getProgramsList";
import { upperCamelCase } from "@/app/helpers/upperCamelCase/upperCamelCase";
import { PostCard } from "@/app/types/PostCard.type";
import { BlockProps } from "@/app/types/BlockProps.type";
import styles from "@/app/styles/Blocks/BloqueListadoProgramas.module.scss";
const ShowsList = dynamic(
	() => import("@/app/components/molecules/ShowsList/ShowsList")
);
const WidgetTitle = dynamic(
	() => import("@/app/components/molecules/WidgetTitle/WidgetTitle")
);
const LinkLoadMore = dynamic(
	() => import("@/app/components/atoms/LinkLoadMore/LinkLoadMore")
);

interface ShowCard extends PostCard {
	schedule: string;
}

type BlockData = {
	id: string;
	type: string;
	blockTitle: string;
	blockSummary: string;
	blockLink: string;
	blockLinkText: string;
	borderColor: string;
	hasTitle: boolean;
	isDark: boolean;
	cmsName: string;
	variant: string;
	shows: [ShowCard];
};

export const BloqueListadoProgramas = async ({
	type,
	id,
	position = 0,
	total,
}: BlockProps) => {
	const { data, ...rest } = await fetchApolloData(GetProgramsHome, {
		getProgramsHomeId: id,
	});

	if (rest?.errors) {
		console.error(rest?.errors);
	}

	const blockData: BlockData = data?.getProgramsHome;
	const { hasTitle = false, isDark = false, shows } = blockData ?? {};
	const borderColor =
		blockData?.borderColor !== "" ? blockData?.borderColor : "transparent";
	const sectionClasses =
		isDark && hasTitle
			? ` ${styles?.dark} ${styles?.darkTitle}`
			: isDark && !hasTitle
			? ` ${styles?.dark}`
			: "";
	const blockType = type?.replace("block_content--", "")?.split("_")?.join(" ");
	const blockDataLayer = {
		event: "select_content",
		clickBloque: undefined,
		colorBloque: `${blockData?.isDark ? "dark" : "light"}`,
		contenidosBloque: `${shows?.length} contenidos`,
		countWidget: total,
		content_name: "SHOW>",
		descripcionBloque: blockData?.cmsName,
		estiloBloque: undefined,
		eventAction: "click",
		posicionBloque: position + 1 ? position + 1 : 0,
		subBloque: undefined,
		tamanoBloque: undefined,
		tipoBloque: upperCamelCase(blockType) ?? undefined,
		tipoElemento: "widget",
		tituloBloque: blockData?.blockTitle ?? undefined,
	};
	const loadMore = {
		id: blockData?.id,
		attr: "_self",
		title: blockData?.blockLinkText || "",
		url: blockData?.blockLink || "",
	};
	const loading = position === 0 || position === 1 ? "eager" : "lazy";

	return (
		<section
			className={`${styles?.programsList} ${sectionClasses}`}
			style={{
				paddingTop: `${position === 0 ? "0px" : ""}`,
			}}
		>
			{hasTitle && (
				<WidgetTitle
					title={blockData?.blockTitle}
					text={blockData?.blockSummary}
					lineColor={borderColor}
				/>
			)}
			<ShowsList
				shows={shows}
				loading={loading}
				blockData={blockDataLayer}
				isDark={isDark}
				isTalentPage={false}
			/>
			<div className={styles?.programsList__cta}>
				<LinkLoadMore
					link={loadMore}
					blockData={blockDataLayer}
					cardPosition={undefined}
				/>
			</div>
		</section>
	);
};
export default BloqueListadoProgramas;
