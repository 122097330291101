import dynamic from "next/dynamic";
import fetchApolloData from "@/app/helpers/fetchApolloData/fetchApolloData";
import GetProgramDetail from "@/app/queries/getShowDetail";
import styles from "@/app/styles/Blocks/BlockDetailProgram.module.scss";
import Image from "next/image";
import { AnchorType } from "@/app/types/Anchor.type";
import { BlockProps } from "@/app/types/BlockProps.type";
import WidgetsMapping from "@/app/mappers/WidgetsMapping";
import { upperCamelCase } from "@/app/helpers/upperCamelCase/upperCamelCase";
const BlockDetailVideo = dynamic(
	() => import("@/app/components/organisms/BlockDetailVideo/BlockDetailVideo")
);
const EpisodesList = dynamic(
	() => import("@/app/components/molecules/EpisodesList/EpisodesList")
);
const InteractiveLink = dynamic(
	() => import("@/app/components/organisms/BloqueHeroGrid/InteractiveLink")
);

export const BloqueDetailProgram = async ({
	type,
	id,
	position = 0,
	total,
}: BlockProps) => {
	const { data, ...rest } = await fetchApolloData(GetProgramDetail, {
		getProgramDetailId: id,
	});
	const showData = data?.getProgramDetail;
	const { showAnchor = false, showAllEpisodes = false } = showData ?? {
		showAnchor: false,
		showAllEpisodes: false,
	};
	const hasEpisodes = showData?.episodes !== null;
	const showClips = showData?.showAsEpisodes; // Show as episodes en realidad es show as clips
	const hasTalent = showData?.anchors !== null && showData?.anchors.length > 0;
	const blocks = showData?.customBlocks?.data;
	const hasBlocks = blocks?.length > 0;

	const videos = showData?.customVideos;
	const blockType = type.replace("block_content--", "").split("_").join(" ");
	const blockDataLayerVideos = {
		event: "select_content",
		// clickBloque: 0,
		colorBloque: undefined,
		contenidosBloque: `${videos?.length} contenidos`,
		countWidget: total,
		descripcionBloque: "DetallePrograma",
		estiloBloque: undefined,
		eventAction: "click",
		posicionBloque: position + 1 ? position + 1 : 0,
		subBloque: undefined,
		tamanoBloque: undefined,
		tipoBloque: upperCamelCase(blockType) ?? undefined,
		tipoElemento: "widget",
		tituloBloque: "Detalle Programa" ?? undefined,
	};
	const EpisodesBlockHeading = `+ Episodios de ${showData?.blockTitle}`;
	const blockDataLayerEpisodes = {
		event: "select_content",
		colorBloque: undefined,
		channel:
			showData?.channelName === "Noticieros" ? "N+" : showData?.channelName,
		contenidosBloque: hasEpisodes
			? `${showData?.episodes.length} contenidos`
			: "0 contenidos",
		countWidget: total,
		descripcionBloque: "ListaEpisodios",
		estiloBloque: undefined,
		eventAction: "click",
		posicionBloque: position + 1 ? position + 1 : 0,
		subBloque: undefined,
		tamanoBloque: undefined,
		tipoBloque: upperCamelCase(blockType) ?? undefined,
		tipoElemento: "LinkText",
		tituloBloque: EpisodesBlockHeading ?? undefined,
	};

	const blockDataLayerTalents = {
		event: "select_content",
		colorBloque: undefined,
		channel:
			showData?.channelName === "Noticieros" ? "N+" : showData?.channelName,
		contenidosBloque:
			hasEpisodes && showData?.anchors
				? `${showData?.anchors.length} contenidos`
				: "0 contenidos",
		countWidget: total,
		descripcionBloque: "ListaTalentos",
		estiloBloque: undefined,
		eventAction: "click",
		posicionBloque: position + 1 ? position + 1 : 0,
		subBloque: undefined,
		tamanoBloque: undefined,
		tipoBloque: upperCamelCase(blockType) ?? undefined,
		tipoElemento: "LinkImage",
		tituloBloque: "NuestroEquipo" ?? undefined,
	};

	return (
		<section className={styles?.mainSection}>
			{videos && (
				<BlockDetailVideo
					videos={{ mainVideo: videos?.[0], relatedVideo: videos }}
					isNotStatic={true}
					fullVersion={false}
					blockData={blockDataLayerVideos}
					showTitle={showData?.blockTitle}
					isProgram={true}
				/>
			)}

			{/* Render de los bloques custom */}
			{hasBlocks &&
				blocks.map((block: any) => WidgetsMapping(block?.type, block?.id))}
			{/* Render de los bloques custom */}
			{/* Render de los bloques custom */}
			{hasBlocks &&
				blocks.map((block: any) => WidgetsMapping(block?.type, block?.id))}
			{/* Render de los bloques custom */}

			{hasEpisodes && showAllEpisodes && (
				<section className={styles?.mainSection__episodesContainer}>
					<div
						style={{
							backgroundColor: "#00283b",
							maxWidth: "1440px",
							margin: "0 auto",
						}}
					>
						<h2>{EpisodesBlockHeading}</h2>
						<EpisodesList
							episodes={showData?.episodes}
							blockData={blockDataLayerEpisodes}
						/>
					</div>
				</section>
			)}
			{hasTalent && showAnchor && (
				<section className={styles?.mainSection__talents}>
					<div className={styles?.mainSection__talents__innerWrapper}>
						<header className={styles?.mainSection__talents__header}>
							<h2>Nuestro Equipo</h2>
							<p>CONOCE AL EQUIPO QUE TE MANTIENE INFORMADO</p>
						</header>
						<ul className={styles?.mainSection__talents__list}>
							{showData?.anchors.map((anchor: AnchorType, i: number) => (
								<li key={anchor?.id}>
									<figure className={styles?.mainSection__talents__card}>
										<InteractiveLink
											url={anchor?.path}
											title={`${anchor?.firstName} ${anchor?.lastName}`}
											blockData={blockDataLayerTalents}
											cardPosition={i}
										>
											<Image
												src={
													anchor?.thumbnail?.imageUrl?.webp ??
													"/assets/placeholder_.svg"
												}
												alt={anchor?.thumbnail?.alt}
												width={anchor?.thumbnail?.width}
												height={anchor?.thumbnail?.height}
											/>
										</InteractiveLink>
										<figcaption>
											<InteractiveLink
												url={anchor?.path}
												title={`${anchor?.firstName} ${anchor?.lastName}`}
												blockData={blockDataLayerTalents}
												cardPosition={i}
											>
												<h3>
													<strong>{anchor?.firstName}</strong> <br />{" "}
													{anchor?.lastName}
												</h3>
											</InteractiveLink>
										</figcaption>
									</figure>
								</li>
							))}
						</ul>
					</div>
				</section>
			)}
		</section>
	);
};
export default BloqueDetailProgram;
