"use client";
import { Fragment } from "react";
import { usePathname } from "next/navigation";
import Link from "next/link";
import ButtonTabs from "@/app/styles/Molecules/ButtonTabs.module.scss";

type ComponentProps = {
	tabData: any;
};

function changeName(name: string) {
	const names: any = {
		"FORO TV": "FORO",
		NOTICIEROS: "N+",
	};

	return names[name.toUpperCase()] || name.toUpperCase();
}

export default function TalentTabs({ tabData }: ComponentProps) {
	const pathName = usePathname();
	return (
		<>
			{tabData
				?.filter(
					(tab: any) =>
						!tab.field_ocultar_de_filtros_talento &&
						tab?.name?.toUpperCase() !== "NMAS MEDIA"
				)
				.sort((element: any) => {
					return element?.name === "FORO TV" ? 1 : -1;
				})
				.map((tab: any) => {
					return (
						<Fragment key={tab.name}>
							<Link
								prefetch={false}
								className={`
                ${ButtonTabs["ButtonTabs__button"]}
                ${
									pathName?.includes(tab.field_url_talentos)
										? ButtonTabs["ButtonTabs__button--active"]
										: ""
								}
              `}
								href={tab.field_url_talentos}
								key={tab.name}
								passHref
							>
								{changeName(tab?.name)}
							</Link>
						</Fragment>
					);
				})}
			<span className={`${ButtonTabs["ButtonTabs__separator"]}`} />
		</>
	);
}
