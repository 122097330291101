"use client";
import {
	Children,
	useEffect,
	useMemo,
	useRef,
	useState,
	MouseEvent,
} from "react";
import { usePathname } from "next/navigation";
import mapboxgl from "mapbox-gl";
import Image from "next/image";

import "./toggleMap.css";
import ToggleMapStyle from "@/app/styles/mapbox/ToggleMap.module.scss";

import {
	upperCamelCase,
	toTitleCase,
	splitName,
	toCommaNotation,
} from "@/app/helpers/";
import { getPartyColor } from "@/app/helpers/mapas/getPartyColor";
import Icon from "../../atoms/Icon/Icon";
import handleSelectContent from "@/app/helpers/analytics/handleSelectcontent";
import InteractiveLink from "../BloqueHeroGrid/InteractiveLink";

type MapboxRest = {
	description: string;
	source: string;
	title: string;
	newsUrl: string;
	lineColor: string;
};

interface ModalProps {
	data: CandidateProps[];
	state: string;
	year: string;
	municipality: string;
	totalValidVotes: string;
	resultsData?: CandidateProps[];
}
interface CandidateProps {
	isImage: boolean;
	urlImage?: string;
	name: string[];
	percent: string | number;
	party: string;
	partyColor: string;
	totalVotes?: number;
	isInfo?: boolean;
}

mapboxgl.accessToken = process?.env?.NEXT_PUBLIC_MAPBOX || "";

const regexNames: RegExp = /(\w+)_(\d)_(\w+)/;
const percentageRegex: RegExp = /(min):(\d+(\.?\d+?))%-(max):(\d+(\.?\d+?))%/;

export const ToggleMap = ({
	styleUrl,
	position,
	total,
	restData,
	type,
}: {
	styleUrl: string;
	type: string;
	position: number;
	total: number | undefined;
	restData: MapboxRest;
}) => {
	const [infoModal, setInfoModal] = useState<ModalProps>({
		data: [],
		state: "",
		year: "",
		municipality: "",
		totalValidVotes: "",
		resultsData: [],
	});
	const blockTypeLayer =
		type?.replace("block_content--", "")?.split("_")?.join(" ") || "";

	const blockDataLayer = useMemo(
		() => ({
			clickBloque: undefined,
			colorBloque: undefined,
			contenidosBloque: undefined,
			content_name: "",
			content_type: "Button",
			contentTitle: undefined,
			countWidget: `${total}` ?? "0",
			descripcionBloque: undefined,
			event: "select_content",
			eventAction: "click",
			posicionBloque: position + 1 ? `${position + 1}` : "0",
			section: undefined,
			subBloque: undefined,
			tamanoBloque: undefined,
			tipoBloque: upperCamelCase(blockTypeLayer || "") ?? undefined,
			tipoElemento: "widget",
			tituloBloque: upperCamelCase(blockTypeLayer || "") ?? undefined,
		}),
		[blockTypeLayer, position, total]
	);
	const mapContainer = useRef(null);
	const map = useRef<mapboxgl.Map | null>(null);

	const path = usePathname();
	// const geoIdElement = useRef<HTMLDivElement | null>(null);
	const [lng, setLng] = useState(-103.254603);
	const [lat, setLat] = useState(25.655617);
	const [maxZoom, setMaxZoom] = useState(9.5);
	const [buttonName, setButtonName] = useState<string[]>();
	const [typeStates, setTypeStates] = useState<string>();
	const [isModalVisible, setIsModalVisible] = useState(false);
	const [widthWindow, setWidthWindow] = useState<number>();
	const [isResultsVisible, setIsResultsVisible] = useState(false);

	const handleRecenter = () => {
		setWidthWindow(window.innerWidth);
		if (!widthWindow) return;
		let initialZoom = 3;
		if (widthWindow > 600 && widthWindow < 1000) {
			initialZoom = 3.6;
		} else if (widthWindow > 1000 && widthWindow < 1279) {
			initialZoom = 3.8;
		} else if (widthWindow >= 1280 && widthWindow < 1439) {
			initialZoom = 4;
		} else if (widthWindow >= 1440) {
			initialZoom = 4.6;
		}
		if (map.current) {
			map.current.flyTo({
				center: [-101.99, 24],
				zoom: initialZoom,
				essential: true,
				bearing: 0,
			});
		}
		handleSelectContent(
			{
				...blockDataLayer,
				content_type: "ButtonMapa",
				content_name: "Restablecer",
			},
			position,
			path || ""
		);
	};

	const handleSelectLayer = (element: MouseEvent<HTMLButtonElement>) => {
		if (!map?.current) return;
		setIsModalVisible(false);
		setIsResultsVisible(false);

		const targetElement = element.target as HTMLButtonElement;
		const wrapperElement = targetElement.parentElement;

		if (!wrapperElement) return;
		Array.from(wrapperElement.children).forEach((element) => {
			element.classList.remove(ToggleMapStyle.toggleMap__button__active);
		});
		targetElement.classList.add(ToggleMapStyle.toggleMap__button__active);

		const layers = map.current.getStyle()?.layers || []; // get all layers\
		layers.forEach((layer) => {
			map?.current!?.setLayoutProperty(layer?.id, "visibility", "none");
		});
		const trueLayers = layers.filter((layer) => {
			const element = layer.id.match(regexNames);
			return Boolean(element) === true;
		});

		const activeLayer = trueLayers.filter((layer) => {
			return (
				layer?.id?.match(regexNames)?.[1] ===
				targetElement.innerText.toLowerCase().replaceAll(" ", "_")
			);
		});

		map?.current?.setLayoutProperty(
			activeLayer?.[0]?.id,
			"visibility",
			"visible"
		);
		setTypeStates(activeLayer?.[0]?.id);
		map?.current?.setLayoutProperty("land", "visibility", "visible");
		map?.current?.setLayoutProperty("NombreEstados", "visibility", "visible");
	};
	const handleRemoveBackground = (background: MouseEvent<HTMLDivElement>) => {
		const targetElement = background.target as HTMLDivElement;
		const wrapperElement = targetElement.parentElement;

		if (!wrapperElement) return;

		Array.from(wrapperElement.children).forEach((element) => {
			if (element.className === `${ToggleMapStyle["toggleMap__background"]}`) {
				element?.classList.add(
					`${ToggleMapStyle["toggleMap__removeBackground"]}`
				);
				setTimeout(() => {
					element?.classList.add(
						`${ToggleMapStyle["toggleMap__removeElement"]}`
					);
				}, 600);
			}
		});
	};

	const handleViewResults = () => {
		setIsModalVisible(false);
		setIsResultsVisible(true);
	};

	useEffect(() => {
		if (map.current) return;
		const widthWindow = window.innerWidth;
		let initialZoom = 3;
		if (widthWindow > 600 && widthWindow < 1000) {
			initialZoom = 3.6;
		} else if (widthWindow > 1000 && widthWindow < 1279) {
			initialZoom = 3.8;
		} else if (widthWindow >= 1280 && widthWindow < 1439) {
			initialZoom = 4;
		} else if (widthWindow >= 1440) {
			initialZoom = 4.6;
		}
		map.current = new mapboxgl.Map({
			container: mapContainer.current || document.createElement("div"),
			style: styleUrl,
			center: [lng, lat],
			zoom: initialZoom,
			maxZoom,
			minZoom: initialZoom,
			pitch: 0,
		});
		map.current.addControl(new mapboxgl.NavigationControl(), "top-right");

		map.current?.on("load", function () {
			if (!map.current) return;
			const layers = map.current.getStyle()?.layers || []; // get all layers

			const trueLayers = layers.filter((layer) => {
				const element = layer.id.match(regexNames);

				return Boolean(element) === true;
			});

			trueLayers.sort((a, b) => {
				const firstElement = parseInt(a?.id?.match(regexNames)?.[2] || "0");
				const secondElement = parseInt(b?.id?.match(regexNames)?.[2] || "0");

				return firstElement - secondElement;
			});

			const nameOfButtons = trueLayers.map((layer) => {
				const name = layer?.id?.match(regexNames)?.[1] || "";
				const cleanName = name.replaceAll("_", " ");
				return cleanName;
			});

			const firstLayerName = trueLayers?.[0]?.id;
			setTypeStates(firstLayerName);
			setButtonName(nameOfButtons);

			if (map?.current) {
				map?.current?.touchPitch?.disable();
				map?.current.dragRotate.disable();
			}
			const currentBounds = map?.current?.getBounds();
			if (currentBounds) {
				map?.current?.setMaxBounds(currentBounds);
			}
		});

		return () => {};
	}, [lat, lng, maxZoom, styleUrl]);

	useEffect(() => {
		if (!map.current) return;
		if (!typeStates) return;
		map.current.on("mousemove", function (event: any) {
			if (map?.current?.loaded()) {
				const features = map.current.queryRenderedFeatures(event.point, {
					layers: [typeStates],
				});

				map.current.getCanvas().style.cursor = features.length ? "pointer" : "";
				// map.current.getCanvas().style.cursor = "pointer";
			}
		});
		map.current.on("move", function () {
			setIsModalVisible(false);
			setIsResultsVisible(false);
		});
		map?.current?.on("click", typeStates, function (e: any) {
			if (!map.current) return;
			const stateFeature: any = e?.features?.[0] || {};
			// const stateFeature = map.current.queryRenderedFeatures(e.point)?.[0];
			const imageUrl = typeStates?.match(regexNames)?.[3] || "";
			if (
				stateFeature?.properties?.FIRST_CANDIDATE_NAME === "0" ||
				!stateFeature?.properties?.FIRST_CANDIDATE_NAME ||
				!stateFeature?.properties?.INFO
			)
				return;
			const hasFoto: boolean = stateFeature?.properties?.FOTO;
			const candidatesInfo: CandidateProps[] = [
				{
					isImage: stateFeature?.properties?.IS_FIRST_IMAGE,
					urlImage: `https://static-mapbox.nmas.com.mx/resultados/${
						imageUrl || " "
					}/${stateFeature?.properties?.FIRST_IMAGE || " "}.webp`,
					name: splitName(stateFeature?.properties?.FIRST_CANDIDATE_NAME),
					party:
						stateFeature?.properties?.FIRST_COALITION_PARTIES === "0"
							? stateFeature?.properties?.FIRST_PARTY?.split("-")?.join(" ")
							: stateFeature?.properties?.FIRST_COALITION_PARTIES?.split(
									"-"
							  )?.join(" "),
					percent:
						stateFeature?.properties?.FIRST_PERCENTAGE === "0" ||
						stateFeature?.properties?.FIRST_PERCENTAGE === 0
							? 0
							: percentageRegex.test(stateFeature?.properties?.FIRST_PERCENTAGE)
							? stateFeature?.properties?.FIRST_PERCENTAGE
							: parseFloat(stateFeature?.properties?.FIRST_PERCENTAGE)
									?.toFixed(2)
									?.toString(),
					partyColor: getPartyColor(
						stateFeature?.properties?.FIRST_PARTY?.toUpperCase()
					),
				},
				{
					isImage: stateFeature?.properties?.IS_SECOND_IMAGE,
					urlImage: `https://static-mapbox.nmas.com.mx/resultados/${
						imageUrl || " "
					}/${stateFeature?.properties?.SECOND_IMAGE || " "}.webp`,
					name: splitName(stateFeature?.properties?.SECOND_CANDIDATE_NAME),
					party:
						stateFeature?.properties?.SECOND_COALITION_PARTIES === "0" ||
						stateFeature?.properties?.SECOND_COALITION_PARTIES === 0
							? stateFeature?.properties?.SECOND_PARTY?.split("-")?.join(" ")
							: stateFeature?.properties?.SECOND_COALITION_PARTIES?.split(
									"-"
							  )?.join(" "),
					percent:
						stateFeature?.properties?.SECOND_PERCENTAGE === "0" ||
						stateFeature?.properties?.SECOND_PERCENTAGE === 0
							? 0
							: percentageRegex.test(
									stateFeature?.properties?.SECOND_PERCENTAGE
							  )
							? stateFeature?.properties?.SECOND_PERCENTAGE
							: parseFloat(stateFeature?.properties?.SECOND_PERCENTAGE)
									?.toFixed(2)
									?.toString(),
					partyColor: getPartyColor(
						stateFeature?.properties?.SECOND_PARTY?.toUpperCase()
					),
				},
				{
					isImage: stateFeature?.properties?.IS_THIRD_IMAGE,
					urlImage: `https://static-mapbox.nmas.com.mx/resultados/${
						imageUrl || " "
					}/${stateFeature?.properties?.THIRD_IMAGE || " "}.webp`,
					name: splitName(stateFeature?.properties?.THIRD_CANDIDATE_NAME),
					party:
						stateFeature?.properties?.THIRD_COALITION_PARTIES === "0" ||
						stateFeature?.properties?.THIRD_COALITION_PARTIES === 0
							? stateFeature?.properties?.THIRD_PARTY?.split("-")?.join(" ")
							: stateFeature?.properties?.THIRD_COALITION_PARTIES?.split(
									"-"
							  )?.join(" "),
					percent:
						stateFeature?.properties?.THIRD_PERCENTAGE === "0" ||
						stateFeature?.properties?.THIRD_PERCENTAGE === 0
							? 0
							: percentageRegex.test(stateFeature?.properties?.THIRD_PERCENTAGE)
							? stateFeature?.properties?.THIRD_PERCENTAGE
							: parseFloat(stateFeature?.properties?.THIRD_PERCENTAGE)
									?.toFixed(2)
									?.toString(),
					partyColor: getPartyColor(
						stateFeature?.properties?.THIRD_PARTY?.toUpperCase()
					),
				},
				{
					isImage: stateFeature?.properties?.IS_FOURTH_IMAGE,
					urlImage: `https://static-mapbox.nmas.com.mx/resultados/${
						imageUrl || " "
					}/${stateFeature?.properties?.FOURTH_IMAGE || " "}.webp`,
					name: splitName(stateFeature?.properties?.FOURTH_CANDIDATE_NAME),
					party:
						stateFeature?.properties?.FOURTH_COALITION_PARTIES === "0" ||
						stateFeature?.properties?.FOURTH_COALITION_PARTIES === 0
							? stateFeature?.properties?.FOURTH_PARTY?.split("-")?.join(" ")
							: stateFeature?.properties?.FOURTH_COALITION_PARTIES?.split(
									"-"
							  )?.join(" "),
					percent:
						stateFeature?.properties?.FOURTH_PERCENTAGE === "0" ||
						stateFeature?.properties?.FOURTH_PERCENTAGE === 0
							? 0
							: percentageRegex.test(
									stateFeature?.properties?.FOURTH_PERCENTAGE
							  )
							? stateFeature?.properties?.FOURTH_PERCENTAGE
							: parseFloat(stateFeature?.properties?.FOURTH_PERCENTAGE)
									?.toFixed(2)
									?.toString(),
					partyColor: getPartyColor(
						stateFeature?.properties?.FOURTH_PARTY?.toUpperCase()
					),
					isInfo: Boolean(
						stateFeature?.properties?.FOURTH_CANDIDATE_NAME || ""
					),
				},
			];

			const resultsInfo: CandidateProps[] = [
				{
					isImage: hasFoto,
					urlImage: `https://static-mapbox.nmas.com.mx/resultados/${
						imageUrl || " "
					}/${stateFeature?.properties?.FIRST_IMAGE_RESULT || " "}.webp`,
					name: splitName(
						stateFeature?.properties?.FIRST_CANDIDATE_NAME_RESULT
					),
					party:
						stateFeature?.properties?.FIRST_COALITION_PARTIES === "0"
							? stateFeature?.properties?.FIRST_PARTY_RESULT?.split("-").join(
									" "
							  )
							: stateFeature?.properties?.FIRST_COALITION_PARTIES_RESULT?.split(
									"-"
							  ).join(" "),
					percent:
						stateFeature?.properties?.FIRST_PERCENTAGE_RESULT === "0" ||
						stateFeature?.properties?.FIRST_PERCENTAGE_RESULT === 0
							? 0
							: percentageRegex.test(
									stateFeature?.properties?.FIRST_PERCENTAGE_RESULT
							  )
							? stateFeature?.properties?.FIRST_PERCENTAGE_RESULT
							: parseFloat(stateFeature?.properties?.FIRST_PERCENTAGE_RESULT)
									?.toFixed(2)
									?.toString(),
					partyColor: getPartyColor(
						stateFeature?.properties?.FIRST_PARTY_RESULT?.toUpperCase()
					),
					totalVotes: stateFeature?.properties?.FIRST_VOTE_RESULT,
				},
				{
					isImage: hasFoto,
					urlImage: `https://static-mapbox.nmas.com.mx/resultados/${
						imageUrl || " "
					}/${stateFeature?.properties?.SECOND_IMAGE_RESULT || " "}.webp`,
					name: splitName(
						stateFeature?.properties?.SECOND_CANDIDATE_NAME_RESULT
					),
					party:
						stateFeature?.properties?.SECOND_COALITION_PARTIES === "0"
							? stateFeature?.properties?.SECOND_PARTY_RESULT?.split("-").join(
									" "
							  )
							: stateFeature?.properties?.SECOND_COALITION_PARTIES_RESULT?.split(
									"-"
							  ).join(" "),
					percent:
						stateFeature?.properties?.SECOND_PERCENTAGE_RESULT === "0" ||
						stateFeature?.properties?.SECOND_PERCENTAGE_RESULT === 0
							? 0
							: percentageRegex.test(
									stateFeature?.properties?.SECOND_PERCENTAGE_RESULT
							  )
							? stateFeature?.properties?.SECOND_PERCENTAGE_RESULT
							: parseFloat(stateFeature?.properties?.SECOND_PERCENTAGE_RESULT)
									?.toFixed(2)
									?.toString(),
					partyColor: getPartyColor(
						stateFeature?.properties?.SECOND_PARTY_RESULT?.toUpperCase()
					),
					totalVotes: stateFeature?.properties?.SECOND_VOTE_RESULT,
				},
				{
					isImage: hasFoto,
					urlImage: `https://static-mapbox.nmas.com.mx/resultados/${
						imageUrl || " "
					}/${stateFeature?.properties?.THIRD_IMAGE_RESULT || " "}.webp`,
					name: splitName(
						stateFeature?.properties?.THIRD_CANDIDATE_NAME_RESULT
					),
					party:
						stateFeature?.properties?.THIRD_COALITION_PARTIES === "0"
							? stateFeature?.properties?.THIRD_PARTY_RESULT?.split("-").join(
									" "
							  )
							: stateFeature?.properties?.THIRD_COALITION_PARTIES_RESULT?.split(
									"-"
							  ).join(" "),
					percent:
						stateFeature?.properties?.THIRD_PERCENTAGE_RESULT === "0" ||
						stateFeature?.properties?.THIRD_PERCENTAGE_RESULT === 0
							? 0
							: percentageRegex.test(
									stateFeature?.properties?.THIRD_PERCENTAGE_RESULT
							  )
							? stateFeature?.properties?.THIRD_PERCENTAGE_RESULT
							: parseFloat(stateFeature?.properties?.THIRD_PERCENTAGE_RESULT)
									?.toFixed(2)
									?.toString(),
					partyColor: getPartyColor(
						stateFeature?.properties?.THIRD_PARTY_RESULT?.toUpperCase()
					),
					totalVotes: stateFeature?.properties?.THIRD_VOTE_RESULT,
				},
				{
					isImage: hasFoto,
					urlImage: `https://static-mapbox.nmas.com.mx/resultados/${
						imageUrl || " "
					}/${stateFeature?.properties?.FOURTH_IMAGE_RESULT || " "}.webp`,
					name: splitName(
						stateFeature?.properties?.FOURTH_CANDIDATE_NAME_RESULT
					),
					party:
						stateFeature?.properties?.FOURTH_COALITION_PARTIES === "0"
							? stateFeature?.properties?.FOURTH_PARTY_RESULT?.split("-").join(
									" "
							  )
							: stateFeature?.properties?.FOURTH_COALITION_PARTIES_RESULT?.split(
									"-"
							  ).join(" "),
					percent: parseFloat(
						stateFeature?.properties?.FOURTH_PERCENTAGE_RESULT
					)
						?.toFixed(2)
						?.toString(),
					partyColor: getPartyColor(
						stateFeature?.properties?.FOURTH_PARTY_RESULT?.toUpperCase()
					),
					totalVotes: stateFeature?.properties?.FOURTH_VOTE_RESULT,
				},
			];

			setInfoModal({
				year: stateFeature?.properties?.STATE_RACE_YR,
				data: candidatesInfo,
				state: stateFeature?.properties?.ESTADO,
				municipality: stateFeature?.properties?.MUNICIPIO,
				totalValidVotes: stateFeature?.properties?.TOTAL_VALID_VOTES || "",
				resultsData: resultsInfo,
			});
			setIsModalVisible(true);
			setIsResultsVisible(false);
		});

		return () => {};
	}, [typeStates]);

	return (
		<>
			<div className={`${ToggleMapStyle["toggleMap__wrapperToggleMap"]}`}>
				<h2
					className={ToggleMapStyle["toggleMap__title"]}
					style={{
						borderTop: `${
							restData.lineColor ? `4px solid ${restData.lineColor}` : ""
						}`,
						paddingTop: `${restData.lineColor ? "8px" : ""}`,
					}}
				>
					{restData.title}
				</h2>
				<div className={`${ToggleMapStyle["toggleMap__wrapperMap"]}`}>
					<div
						className={`${ToggleMapStyle["toggleMap__background"]}`}
						onClick={(event: MouseEvent<HTMLDivElement>) => {
							handleRemoveBackground(event);
						}}
					>
						Haz clic para interactuar con el mapa
					</div>
					<div
						ref={mapContainer}
						className={`${ToggleMapStyle["toggleMap"]}`}
					/>
					<div className={`${ToggleMapStyle["toggleMap__wrapperInfoFrame"]}`}>
						<span
							className={`${ToggleMapStyle["toggleMap__infoFrameDescription"]}`}
						>
							{restData?.description}
						</span>

						<div
							className={`${ToggleMapStyle["toggleMap__wrapperContainerButtons"]}`}
						>
							{Children.toArray(
								buttonName?.map((name, index) => {
									return (
										<>
											<button
												className={`${ToggleMapStyle["toggleMap__button"]} ${
													index === 0
														? ToggleMapStyle["toggleMap__button__active"]
														: ""
												}`}
												onClick={(event: MouseEvent<HTMLButtonElement>) => {
													handleSelectLayer(event);
												}}
											>
												{toTitleCase(name)}
											</button>
										</>
									);
								})
							)}
						</div>
						{restData?.newsUrl ? (
							<InteractiveLink
								blockData={{
									...blockDataLayer,
									content_type: "ButtonMapa",
									content_name: "Conoce la Metodologia N+",
								}}
								url={restData?.newsUrl || "/"}
								isLink={true}
								className={`${ToggleMapStyle["toggleMap__wrapperInfoFrame__text"]}`}
							>
								Conoce la metodología
							</InteractiveLink>
						) : (
							<></>
						)}
					</div>
					{/* Start Modal */}
					<div
						className={`${ToggleMapStyle["toggleMap__modal"]} ${
							isModalVisible
								? ToggleMapStyle["toggleMap__modal-open"]
								: ToggleMapStyle["toggleMap__modal-close"]
						}`}
					>
						<div
							className={`${ToggleMapStyle["toggleMap__modal__wrapperHead"]}`}
						>
							<div
								className={`${ToggleMapStyle["toggleMap__modal__wrapperInfo"]}`}
							>
								<div
									className={`${ToggleMapStyle["toggleMap__modal__wrapperInfo"]}`}
								>
									<div
										className={`${ToggleMapStyle["toggleMap__modal__stateName"]}`}
									>
										{infoModal?.municipality ? (
											<>
												<span>Muncipio | {infoModal?.municipality}</span>
											</>
										) : (
											<>{infoModal.state}</>
										)}
									</div>
								</div>
								<span
									onClick={() => {
										setIsModalVisible(false);
									}}
								>
									<Icon
										name="close"
										className={`${ToggleMapStyle["toggleMap__modal__closeIcon"]}`}
									/>
								</span>
							</div>

							{/* <div
								className={`${ToggleMapStyle["toggleMap__modal__yearInfo"]}`}
							>
								{infoModal.year}
							</div> */}
							<div
								style={{
									color: "white",
									display: "flex",
									justifyContent: "space-between",
								}}
							>
								<div>
									<span
										className={`${ToggleMapStyle["toggleMap__modal__yearInfo"]}`}
									>
										{infoModal.year}
									</span>{" "}
									<div
										className={`${ToggleMapStyle["toggleMap__modal__validVotes"]}`}
										style={{
											display: `${!infoModal?.totalValidVotes ? "none" : ""}`,
										}}
									>
										| Total de votos válidos :{" "}
										{toCommaNotation(Number(infoModal?.totalValidVotes)) || ""}
									</div>
								</div>

								<div
									className={`${ToggleMapStyle["toggleMap__modal__seeResults"]}`}
									style={{
										display: `${!infoModal?.totalValidVotes ? "none" : ""}`,
									}}
									onClick={() => {
										handleViewResults();
									}}
								>
									Ver resultados
								</div>
							</div>
						</div>
						{Children.toArray(
							infoModal.data.map((candidate, index, array) => {
								return (
									<div
										className={`${ToggleMapStyle["toggleMap__modal__wrapperCandidate"]}`}
										style={{
											display: `${
												candidate.name[0] === "0" || !candidate?.name?.[0]
													? "none"
													: ""
											}`,
											borderRadius: `${
												index === array.length - 1 ? "8px" : ""
											}`,
										}}
									>
										<div
											className={`${ToggleMapStyle["toggleMap__modal__candidate"]}`}
										>
											<div
												className={`${ToggleMapStyle["toggleMap__modal__infoCandidate"]}`}
											>
												<div
													style={{
														display: `${
															!candidate.urlImage || !candidate?.isImage
																? "none"
																: ""
														}`,
													}}
												>
													{!candidate.isImage ? (
														<></>
													) : (
														<Image
															src={candidate?.urlImage || ""}
															alt={candidate.name[0]}
															width={50}
															height={50}
															loading="lazy"
															placeholder="blur"
															blurDataURL={`${
																process.env.NEXT_PUBLIC_BLURIMAGE ||
																"data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAIAAACQd1PeAAAADElEQVR4nGP4b+YAAAOtAXam2gnIAAAAAElFTkSuQmCC"
															}`}
															className={`${ToggleMapStyle["toggleMap__modal__image"]}`}
														/>
													)}
												</div>
												<div
													className={`${ToggleMapStyle["toggleMap__modal__wrapperCandidate"]}`}
												>
													<div
														className={`${ToggleMapStyle["toggleMap__modal__paryName"]}`}
														style={{
															color: `${candidate?.partyColor || "gray"}`,
														}}
													>
														{candidate.party}
													</div>

													<span
														className={`${ToggleMapStyle["toggleMap__modal__candidateName"]}`}
													>
														{candidate.name[0]} {candidate.name[1]}
													</span>
												</div>
											</div>
											<div
												style={{
													display: `${!candidate?.percent ? "none" : ""}`,
												}}
											>
												<div
													className={`${ToggleMapStyle["toggleMap__modal__wrapperPercent"]}`}
												>
													<div
														className={`${ToggleMapStyle["toggleMap__modal__numberPercent"]}`}
													>
														{!percentageRegex?.test(
															candidate.percent.toString()
														) && <>{candidate.percent}</>}
														{percentageRegex?.test(
															candidate.percent.toString()
														) && (
															<>
																<div
																	className={`${ToggleMapStyle["toggleMap__modal__minMaxPercentage"]}`}
																>
																	<div
																		className={`${ToggleMapStyle["toggleMap__modal__wrapperMinMaxPercentage"]}`}
																	>
																		<span
																			className={`${ToggleMapStyle["toggleMap__modal__minPercentage--text"]}`}
																		>
																			MIN&nbsp;
																		</span>
																		<span
																			className={`${ToggleMapStyle["toggleMap__modal__minPercentage--value"]}`}
																		>
																			{candidate?.percent
																				.toString()
																				.match(percentageRegex)?.[2] || ""}
																			<span
																				className={`${ToggleMapStyle["toggleMap__modal__minPercentage--character"]}`}
																			>
																				%
																			</span>
																		</span>
																	</div>
																	<div
																		className={`${ToggleMapStyle["toggleMap__modal__wrapperMinMaxPercentage"]}`}
																	>
																		<span
																			className={`${ToggleMapStyle["toggleMap__modal__maxPercentage--text"]}`}
																		>
																			MÁX&nbsp;
																		</span>
																		<span
																			className={`${ToggleMapStyle["toggleMap__modal__maxPercentage--value"]}`}
																		>
																			{candidate?.percent
																				.toString()
																				.match(percentageRegex)?.[5] || ""}
																			<span
																				className={`${ToggleMapStyle["toggleMap__modal__maxPercentage--character"]}`}
																			>
																				%
																			</span>
																		</span>
																	</div>
																</div>
															</>
														)}
													</div>
													<div
														className={`${ToggleMapStyle["toggleMap__modal__percent"]}`}
														style={{
															display: `${
																percentageRegex?.test(
																	candidate.percent.toString()
																)
																	? "none"
																	: ""
															}`,
														}}
													>
														%
													</div>
												</div>
											</div>
										</div>
										<div
											className={`${ToggleMapStyle["toggleMap__modal__wrapperLine"]}`}
											style={{
												display: `${
													!candidate?.percent ||
													percentageRegex.test(candidate?.percent.toString())
														? "none"
														: ""
												}`,
											}}
										>
											<div
												className={`${ToggleMapStyle["toggleMap__modal__lineColor"]}`}
												style={{
													backgroundColor: `${candidate?.partyColor || "gray"}`,
													width: `${candidate?.percent || 0}%`,
												}}
											/>
										</div>
									</div>
								);
							})
						)}
					</div>
					{/* End Modal */}

					{/* Result Modal */}
					<div
						className={`${ToggleMapStyle["toggleMap__modal"]} ${
							isResultsVisible
								? ToggleMapStyle["toggleMap__modal-open"]
								: ToggleMapStyle["toggleMap__modal-close"]
						}`}
					>
						<div
							className={`${ToggleMapStyle["toggleMap__modal__wrapperHead"]}`}
						>
							<div
								className={`${ToggleMapStyle["toggleMap__modal__wrapperInfo"]}`}
							>
								<div
									className={`${ToggleMapStyle["toggleMap__modal__stateName"]}`}
								>
									Resultados Nacionales
								</div>
								<span
									onClick={() => {
										setIsResultsVisible(false);
									}}
								>
									<Icon
										name="close"
										className={`${ToggleMapStyle["toggleMap__modal__closeIcon"]}`}
									/>
								</span>
							</div>

							<div
								className={`${ToggleMapStyle["toggleMap__modal__wrapperInfo"]}`}
							>
								<div
									className={`${ToggleMapStyle["toggleMap__modal__yearInfo"]}`}
								>
									{infoModal.year}
								</div>
							</div>
						</div>
						{Children.toArray(
							infoModal?.resultsData?.map((candidate, index, array) => {
								return (
									<div
										className={`${ToggleMapStyle["toggleMap__modal__wrapperCandidate"]}`}
										style={{
											display: `${
												candidate.name[0] === "0" || !candidate?.name?.[0]
													? "none"
													: ""
											}`,
											borderRadius: `${
												index === array.length - 1 ? "8px" : ""
											}`,
										}}
									>
										<div
											className={`${ToggleMapStyle["toggleMap__modal__candidate"]}`}
										>
											<div
												className={`${ToggleMapStyle["toggleMap__modal__infoCandidate"]}`}
											>
												<div
													style={{
														display: `${
															!candidate.urlImage || !candidate?.isImage
																? "none"
																: ""
														}`,
													}}
												>
													{!candidate?.isImage ? (
														<></>
													) : (
														<Image
															src={candidate?.urlImage || ""}
															alt={candidate?.name?.[0]}
															width={50}
															height={50}
															loading="lazy"
															placeholder="blur"
															blurDataURL={`${
																process.env.NEXT_PUBLIC_BLURIMAGE ||
																"data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAIAAACQd1PeAAAADElEQVR4nGP4b+YAAAOtAXam2gnIAAAAAElFTkSuQmCC"
															}`}
															className={`${ToggleMapStyle["toggleMap__modal__image"]}`}
														/>
													)}
												</div>
												<div
													className={`${ToggleMapStyle["toggleMap__modal__wrapperCandidate"]}`}
												>
													<div
														className={`${ToggleMapStyle["toggleMap__modal__paryName"]}`}
														style={{
															color: `${candidate?.partyColor || "gray"}`,
														}}
													>
														{candidate.party}
													</div>

													<span
														className={`${ToggleMapStyle["toggleMap__modal__candidateName"]}`}
													>
														{candidate.name[0]} {candidate.name[1]}
													</span>
												</div>
											</div>
											<div>
												<div
													className={`${ToggleMapStyle["toggleMap__modal__wrapperPercent"]}`}
												>
													<div
														className={`${ToggleMapStyle["toggleMap__modal__numberPercent"]}`}
													>
														{candidate.percent}
													</div>
													<div
														className={`${ToggleMapStyle["toggleMap__modal__percent"]}`}
													>
														%
													</div>
												</div>
												<div
													className={`${ToggleMapStyle["toggleMap__modal__noVotes"]}`}
												>
													{toCommaNotation(candidate?.totalVotes || 0)}
												</div>
											</div>
										</div>
										<div
											className={`${ToggleMapStyle["toggleMap__modal__wrapperLine"]}`}
										>
											<div
												className={`${ToggleMapStyle["toggleMap__modal__lineColor"]}`}
												style={{
													backgroundColor: `${candidate?.partyColor || "gray"}`,
													width: `${candidate?.percent || 0}%`,
												}}
											/>
										</div>
									</div>
								);
							})
						)}
					</div>
					{/* End result Modal */}
					<Image
						className={ToggleMapStyle["toggleMap__logo-bottom-left"]}
						src={"/assets/Logo/Logo-N+-Neutro-Oscuro.svg"}
						alt="nmas"
						width={50}
						height={50}
						loading="lazy"
						placeholder="blur"
						blurDataURL={`${
							process.env.NEXT_PUBLIC_BLURIMAGE ||
							"data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAIAAACQd1PeAAAADElEQVR4nGP4b+YAAAOtAXam2gnIAAAAAElFTkSuQmCC"
						}`}
					/>
					<button
						className={`${ToggleMapStyle["toggleMap__recenterButton"]}`}
						onClick={handleRecenter}
					/>
				</div>
			</div>
		</>
	);
};
